import { Badge, Tooltip } from "antd";
import { useContext } from "react";
import AntDDrawer from "components/Drawer/AntDDrawer";
import UserChatDrawer from "pages/UserChat/shared/UserChatDrawer";
import { ChatContext } from "context/ChatContext";
import { useDispatch, useSelector } from "react-redux";
import { toggleChatDrawer } from "redux/UserChatRedux";
import { IoChatbubbleEllipses } from "react-icons/io5";

const UserChatNavIcon = () => {
  const { unreadChats, chats } = useContext(ChatContext);
  const dispatch = useDispatch<any>();
  const { openChatDrawer } = useSelector((state: any) => state?.userChat);

  const showDrawer = () => {
    dispatch(toggleChatDrawer(true));
  };
  const closeDrawer = () => {
    dispatch(toggleChatDrawer(false));
  };
  return (
    <>
      <Tooltip title="Chat">
        <div
          className="flex items-center text-white cursor-pointer text-[25px] max-sm:ml-4"
          onClick={showDrawer}
        >
          <Badge count={unreadChats.length} overflowCount={10} size="default">
            {/* <MdOutlineChat className="text-2xl text-white" /> */}
            <IoChatbubbleEllipses className="text-2xl text-white" />
          </Badge>
        </div>
      </Tooltip>
      {/* drawer */}
      <AntDDrawer
        onClose={closeDrawer}
        open={openChatDrawer}
        children={
          <UserChatDrawer chatFromProfile={false} onClose={closeDrawer} />
        }
      />
    </>
  );
};

export default UserChatNavIcon;
